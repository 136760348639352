.stat-box{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 30vw;
    padding: 1vw;
    border-radius: 15px;
}

.stat-title{
    background-color: #291536;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    color: white;
    text-align: center;
    font-size: 3.5vh;
    font-family: 'Premier League', sans-serif;
    border-top-left-radius: 2.5vh;
    border-top-right-radius: 2.5vh;
}

.player-box{
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 3.5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.player-boxes{
    flex: 1;
    color: white;
    background-image: linear-gradient(45deg, #3B99FC 25%, #3BA9FF 25%, #3BA9FF 50%, #3B99FC 50%, #3B99FC 75%, #3BA9FF 75%, #3BA9FF);
    background-size: 10vw 10vw;
    border-bottom-left-radius: 2.5vh; 
    border-bottom-right-radius: 2.5vh;
}
