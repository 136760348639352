.team-page{
    display: flex;
    flex-direction: column;
}

.team-page-body{
    display: flex;
    flex-direction: row;
    padding-top: 6vh;
    align-items: center;
    height: calc(100vh - 6vh);
    width: 100vw;
}

.logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40vw;
}

.club-logo{
    height: 80vh;
    width: 35vw;
}

.team-info{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.stats{
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fixtures{
    display: flex;
    height: 90%;
    width: 45%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}