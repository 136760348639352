.navBar{
    flex: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 6vh;
    background-color: #291536;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 1px 1px 1px 1px black;
  }

  .home-icon{
    width: 1.8vw;
    height: auto;
  }
  
  .navButton{
    flex: 0;
    border: none;
    background: none;
    width: 2.5vw;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .navButton:hover{
    cursor: pointer;
    transform: scale(1.25);
  }
  
  .navTitle{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 4vh;
    padding-right: 2.5vw;
    font-family: 'Premier League', sans-serif;
    font-weight: 400;  
  }