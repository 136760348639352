.logo {
    height: 5vw;
    width: auto;
    margin: 0.3vw;
    display: flex;
    justify-content: center;
}
  
.logo-image {
    height: 100%;
    width: auto;
    transition: transform 0.3s ease;
}
  
.logo-image:hover {
    transform: scale(1.3);
}