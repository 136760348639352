.matches-box{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 22vw;
    height: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 15px;
}

.stat-title{
    flex: 0;
    background-color: #291536;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    color: white;
    text-align: center;
    font-size: 3.5vh;
    font-family: 'Premier League', sans-serif;
    border-top-left-radius: 2.5vh;
    border-top-right-radius: 2.5vh;
}

.fixture-box{
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 3.5vh;
    display: flex;
    flex-direction: column;
    justify-content: cetner;
    align-items: center;
}

.fixture-boxes{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    color: white;
    background-image: linear-gradient(45deg, #3B99FC 25%, #3BA9FF 25%, #3BA9FF 50%, #3B99FC 50%, #3B99FC 75%, #3BA9FF 75%, #3BA9FF);
    background-size: 10vw 10vw;
    border-bottom-left-radius: 2.5vh; 
    border-bottom-right-radius: 2.5vh;

    .loading-box{
        width: 100%;
        height: 100%;
        border-radius: inherit;
        font-family: 'Premier League', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 2vw;
    }
}

.fixture-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.date{
    text-align: center;
}

.competition{
    text-align: center;
}

.time{
    width: 33%;
    text-align: center;
}

.home-team{
    width: 33%;
    text-align:left;
    display: flex;
    justify-content: center;
}

.away-team{
    width: 33%;
    text-align: right;
    display: flex;
    justify-content: center;
}

